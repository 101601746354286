<template>
  <div
    v-if="humans.length"
    class="pl-1 pr-1"
  >
    <p class="mb-1 ml-2">Выберите:</p>
    <v-select
      dense
      outlined
      :item-text="(human) => (human.imya || '') + ' ' + (human.familiya || '') + ' ' + (human.otchestvo || '')"
      item-value="id"
      v-model="currentHuman"
      :items="humans"
      hide-details
      hide-messages
      class="mb-2"
    >
    </v-select>
    <DownloadReport
      v-if="currentHuman >= 0 && report.params.config.length > 0"
      command-type="reporting"
      :params-to-upload="report"
    />
    <BaseAlert
      v-else-if="currentHuman >= 0"
      text="Не указана фамилия"
      color="primary"
      type="info"
    />
  </div>
  <div v-else>
    <BaseAlert
      text="Нет отчета"
      color="primary"
      type="info"
    >
    </BaseAlert>
  </div>

</template>

<script>
import DownloadReport from '@/components/views/account/passport/detail/views/passportReports/DownloadReport'
import _ from 'lodash'
import ConstructorFiles from '@/components/structures/constructorFiles'
import BaseAlert from '@/components/base/UI/BaseAlert'

export default {
  name: 'ReportBurialCertificate',
  components: { BaseAlert, DownloadReport },
  props: {
    value: Object
  },
  data () {
    return {
      detail: this.value,
      currentHuman: undefined,
      defaultQuantity: 5 // humans
    }
  },
  computed: {
    report () {
      const baseRep = { ...new ConstructorFiles({ commandType: 'reporting' }).data }
      if (this.humans[this.currentHuman] && this.humans[this.currentHuman].familiya) {
        baseRep.params.config.push({
          type: 'spravkaOZahoronenii',
          options: {
            query: {
              'objectId': this.detail.properties.id,
              'passportId': this.detail.properties.passport_id,
              'family': this.humans[this.currentHuman].familiya,
              'name': this.humans[this.currentHuman].imya,
              'fatherName': this.humans[this.currentHuman].otchestvo
            }
          }
        })
      }
      return baseRep
    },
    humans () {
      const baseName = 'imya'
      const baseSur = 'familiya' // фамилия
      const baseFam = 'otchestvo' //отчество
      const listHumans = []
      let index = 0 // индекс для удобства для currentHuman и report
      for (let key = 0; key <= this.defaultQuantity; key++) { // цикл по к-ву людей
        const hasName = this.detail.properties[baseName + (key === 0 ? '' : key)] //is has imya1/imya2/imya3...
        const hasFam = this.detail.properties[baseFam + (key === 0 ? '' : key)]
        const hasOtch = this.detail.properties[baseFam + (key === 0 ? '' : key)]
        if (hasName && hasOtch || hasFam) {
          const endNameAttr = key === 0 ? '' : key // конечная цифра атрибутов, если кей = 0, то цифры не должно быть
          listHumans.push({
            imya: this.detail.properties.hasOwnProperty(baseName + endNameAttr) ? this.detail.properties[baseName + endNameAttr] : null, //если есть значение, то взять его
            familiya: this.detail.properties.hasOwnProperty(baseSur + endNameAttr) ? this.detail.properties[baseSur + endNameAttr] : null,
            otchestvo: this.detail.properties.hasOwnProperty(baseFam + endNameAttr) ? this.detail.properties[baseFam + endNameAttr] : null,
            id: index
          })
          index++
        }
      }
      return listHumans
    }
  },
  methods: {
    maxCountHumans (quantity) {
      if (_.has(this.detail.properties, 'imya' + (this.defaultQuantity + 1))) { //функция на случай, если людей(атрибутов) станет больше 5.
        this.defaultQuantity += 1
        this.maxCountHumans(quantity)
      }
    }
  },
  mounted () {
    this.maxCountHumans(this.defaultQuantity)
  }
}
</script>